import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import GridContainer from "../components/Layout/GridContainer"
import {
  H2,
  ParagraphRegular,
} from "../components/shared/Typography/Typography"
import Button from "../components/shared/Button/Button"
import ContactForm from "../components/ContactPage/ContactForm"
import Layout from "../components/Layout/Layout"
import { LayoutContext } from "../components/Layout/context/LayoutContext"
import { device } from "../components/shared/styles/breakpoints"
import Seo from "../components/Seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

const StyledContactContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  z-index: 10;
  padding-bottom: ${({ theme }) => theme.spaces[64]};
  top: ${({ theme }) => theme.sizes.header};
  left: 0;
  right: 0;

  @media ${device.lg} {
    position: fixed;
    bottom: 0;
  }
`

const StyledContactContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spaces[80]};
  align-items: center;
  gap: ${({ theme }) => theme.spaces[64]};
  @media ${device.lg} {
    justify-content: space-between;
    gap: ${({ theme }) => theme.spaces[32]};
    flex-direction: row;
    align-items: flex-start;
  }
`

const StyledContactColumn = styled.div`
  max-width: 480px;
  width: 100%;
`

const StyledContactTitle = styled(H2)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[16]}`};
  @media ${device.lg} {
    margin: ${({ theme }) => `0 0 ${theme.spaces[24]}`};
  }
`

const StyledContactParagraph = styled(ParagraphRegular)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[32]}`};
`

const StyledFormColumn = styled(StyledContactColumn)`
  @media ${device.lg} {
    max-height: calc(100vh - 5rem - 72px);
    overflow-y: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export default function ContactPage () {
  const { t } = useTranslation()
  const { setOpenContactForm, setOpenEstimateForm } = useContext(LayoutContext)
  useEffect(() => {
    setOpenContactForm(true)
    return () => {
      setOpenContactForm(false)
    }
  }, [setOpenContactForm])

  return (
    <Layout>
      <Seo title={t("Kontakt")} />
      <StyledContactContainer>
        <GridContainer>
          <StyledContactContent>
            <StyledContactColumn>
              <StyledContactTitle>
                {t("contactFormTitle")}
              </StyledContactTitle>
              <StyledContactParagraph>
                {t("contactFormText")}
              </StyledContactParagraph>
              <Button sufixIcon color="primary" onClick={() => setOpenEstimateForm(true)}>
                {t("Wyceń projekt")}
              </Button>
            </StyledContactColumn>
            <StyledFormColumn>
              <ContactForm />
            </StyledFormColumn>
          </StyledContactContent >
        </GridContainer >
      </StyledContactContainer >
    </Layout >
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
