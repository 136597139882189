import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { H4 } from "../shared/Typography/Typography"
import Input from "../shared/FormComponents/Input"
import Textarea from "../shared/FormComponents/Textarea"
import { Divider } from "../shared/Divider/Divider"
import Button from "../shared/Button/Button"
import FileInput from "../shared/FormComponents/FileInput"
import { useForm, ValidationError } from "@formspree/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { device } from "../shared/styles/breakpoints"

const StyledFormContainer = styled.div`
  width: 100%;

  @media ${device.lg} {
    height: 800px;
  }
`
const StyledForm = styled.form`
  input,
  textarea {
    width: 100%;
    display: block;
  }
`

const StyledFormTitle = styled(H4)`
  margin: ${({ theme }) => `0 0 ${theme.spaces[40]}`};
`

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.spaces[40]} 0`};
`

export default function ContactForm () {
  const { t } = useTranslation()
  const [state, handleSubmit] = useForm("mgedenpy");
  const [buttonText, setButtonText] = useState(t("Wyślij wiadomość"));

  useEffect(() => {

    if (!state.submitting && state.succeeded) {
      setButtonText(t("Wysłano!"))
      setTimeout(() => {
        setButtonText(t("Wyślij wiadomość"))
      }, 2000);
    }
    return () => {
      clearTimeout()
    }
  }, [state.succeeded, state.submitting])

  return (
    <StyledFormContainer>
      <StyledFormTitle>{t("Cześć, jesteśmy Frenzy. A Ty?")}</StyledFormTitle>
      <StyledForm
        method="POST"
        onSubmit={handleSubmit}
        enctype="multipart/form-data"
      >
        <Input
          label={t("Imię")}
          placeholder="Jan"
          name="name"
          type="text"
          required
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} />
        <Input
          label="E-mail"
          placeholder="jan@kowalski.pl"
          name="email"
          type="email"
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <Input
          label={t("Nazwa firmy")}
          placeholder="Jan"
          name="companyName"
          type="text"
        />
        <ValidationError
          prefix="CompanyName"
          field="companyName"
          errors={state.errors}
        />
        <Textarea
          label={t("Chcesz coś dodać?")}
          placeholder={t("Wystarczy kilka słów o zakresie lub samym produkcie")}
          name="message"
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <FileInput label={t("Załącz materiały")} name="attachment" accept="application/msword, application/pdf" />
        <ValidationError
          prefix="Attachment"
          field="attachment"
          errors={state.errors}
        />
        <StyledDivider />
        <Button
          sufixIcon
          type="submit"
          disabled={state.submitting}
        >
          {buttonText}
        </Button>
      </StyledForm>
    </StyledFormContainer >
  )
}
